/* You can add global styles to this file, and also import other style files */

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: rgb(0, 255, 200);
}
